export const colorResolver = (value) => {
  if (value <= 1) {
    return '#ff4545';
  } else if (value > 1 && value <= 2) {
    return '#ffa534';
  } else if (value > 2 && value <= 3) {
    return '#ffe234';
  } else if (value > 3 && value <= 4) {
    return '#b7dd29';
  } else if (value > 4 && value <= 5) {
    return '#57e32c';
  } else {
    return '#708090';
  }
};
