import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const { login } = useAuth();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    // Envoie une requête POST à l'API pour vérifier le mot de passe
    try {
      const response = await fetch('https://api.eos.agency/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }), // Envoie du mot de passe au serveur
      });

      const data = await response.json();

      if (data.success) {
        login(); // Active l'authentification côté client
        navigate('/'); // Redirige vers la page principale
      } else {
        setError('Mot de passe incorrect');
      }
    } catch (err) {
      setError('Erreur lors de la connexion');
    }
  };

  return (
    <div>
      <h2>Connexion</h2>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Entrez votre mot de passe"
      />
      <button onClick={handleLogin}>Se connecter</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default LoginPage;
