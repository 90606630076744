import React, { createContext, useContext, useState } from 'react';


// Créer le contexte
const AuthContext = createContext('auth');

// Fournisseur d'authentification
export const AuthProvider = ({ children,router }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  // Fonction de connexion (avec token)
  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  // Fonction de déconnexion
  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    router.push('/login')

  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte d'authentification
export const useAuth = () => {
  return useContext(AuthContext);
};
