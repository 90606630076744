import '../App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { useAuth } from '../context/AuthContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  linkBodyTemplate,
  statusBodyTemplate,
  deleteBodyTemplate,
  phoneBodyTemplate,
  nameBodyTemplate,
  answeredTemplate,
  statusRowFilterTemplate
} from '../components/list';

export const List = () => {
  const [customers, setCustomers] = useState([]);
  const [state, setState] = useState('');
  const [error, setError] = useState('');
  const { logout } = useAuth();

  useEffect(() => {
    (async () => {
      setState('loading');
      try {
        const res = await axios.get('https://api.eos.agency/customers');
        console.log(Object.values(res.data));
        setCustomers(Object.values(res.data));
        setState('success');
      } catch (e) {
        setError(e);
      }
    })();
  }, []);

  return (
    <div className="App">
        <Button icon="pi pi-sign-out" className="p-button-rounded p-button-danger" aria-label="Cancel" onClick={() => {
        logout()
        

        }
          } />
      <DataTable
        value={customers}
        paginator
        rows={15}
        rowsPerPageOptions={[15, 50, 100]}
      >
        <Column header="Téléphone" body={phoneBodyTemplate}/>
        <Column sortable field="createdAt" header="Date de création"/>
        <Column field="email" header="Email"/>
        <Column header="Nom" body={nameBodyTemplate}/>
        <Column field="site" header="Site"/>
        <Column
          sortable
          field="emailStatus"
          header="Crawled"
          body={statusBodyTemplate}
          showFilterMenu
          filter
          filterMenuStyle={{ width: '14rem' }}
          style={{ minWidth: '12rem' }}
        />

        <Column sortable field="answered" header="Answered"/>
        <Column
          sortable
          field="emailStatus"
          header="Email Status"
          body={answeredTemplate}
        />
        <Column header="Résultats" body={linkBodyTemplate}/>
        {/*<Column header="Supprimer" body={deleteBodyTemplate}></Column>*/}
      </DataTable>
    </div>
  );
};
