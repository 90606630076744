import '../App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, redirect } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';

import styled from 'styled-components';

export const CrawlForm = () => {
  const [errors, setErrors] = useState({});
  const [customer, setCustomer] = useState({});
  const [sended, setSended] = useState(false);

  const { id } = useParams();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!customer.firstName.trim()) {
      errors.firstName = 'Le prénom est obligatoire';
    }

    if (!customer.lastName.trim()) {
      errors.lastName = 'Le nom est obligatoire';
    }

    if (!customer.email.trim()) {
      errors.email = "L'adresse e-mail est obligatoire";
    } else if (!/^\S+@\S+\.\S+$/.test(customer.email)) {
      errors.email = "L'adresse e-mail n'est pas valide";
    }

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    try {
      await axios.post('https://api.eos.agency/customers', customer);
      setSended(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Card>
        {sended && (
          <SubmitedMessageContainer>
            <SubmitedMessage>
              Merci d'avoir soumis votre demande sur notre site. Nous avons bien
              reçu votre demande et nous l'examinerons dans les plus brefs
              délais.
              <br />
              <br />
              Un message de confirmation a été envoyé à l'adresse e-mail que
              vous avez fournie lors de votre inscription. Veuillez vérifier
              votre boîte de réception (et peut-être votre dossier spam) pour
              trouver ce message.
              <br />
              <br />
              Si vous ne recevez pas le message de confirmation dans les
              prochaines heures, veuillez nous contacter pour que nous puissions
              résoudre le problème.
              <br />
              <br />
              Nous vous remercions de votre intérêt pour notre site et nous
              espérons que nous pourrons répondre à vos besoins. Si vous avez
              d'autres questions ou préoccupations, n'hésitez pas à nous
              contacter.
            </SubmitedMessage>
          </SubmitedMessageContainer>
        )}
        {!sended && (
          <>
            <h1>Inscription</h1>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="firstName">Prénom</Label>
                <Input
                  type="text"
                  id="firstName"
                  value={customer.firstName}
                  onChange={(e) =>
                    setCustomer({ ...customer, firstName: e.target.value })
                  }
                />
                {errors.firstName && <Error>{errors.firstName}</Error>}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="lastName">Nom</Label>
                <Input
                  type="text"
                  id="lastName"
                  value={customer.lastName}
                  onChange={(e) =>
                    setCustomer({ ...customer, lastName: e.target.value })
                  }
                />
                {errors.lastName && <Error>{errors.lastName}</Error>}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="society">Société</Label>
                <Input
                  type="text"
                  id="society"
                  value={customer.society}
                  onChange={(e) =>
                    setCustomer({ ...customer, society: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">E-mail</Label>
                <Input
                  type="email"
                  id="email"
                  value={customer.email}
                  onChange={(e) =>
                    setCustomer({ ...customer, email: e.target.value })
                  }
                />
                {errors.email && <Error>{errors.email}</Error>}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="site">Site web</Label>
                <Input
                  type="text"
                  id="site"
                  value={customer.site}
                  onChange={(e) =>
                    setCustomer({ ...customer, site: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="phone">Téléphone</Label>
                <Input
                  type="text"
                  id="phone"
                  value={customer.phone}
                  onChange={(e) =>
                    setCustomer({ ...customer, phone: e.target.value })
                  }
                />
              </FormGroup>
              <Button type="submit">S'inscrire</Button>
            </form>
          </>
        )}
      </Card>
    </Container>
  );
};
const SubmitedMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
`;
const SubmitedMessage = styled.p``;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding:20px
`;

const Card = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  &:focus {
    border-color: #007bff;
  }
`;

const Error = styled.span`
  color: red;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #0062cc;
  }
`;
