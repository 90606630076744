import styled from 'styled-components';
const Image = ({ src }) => (
  
    <Img
      src={src}
    />
  
);

const Img = styled.img`
  width:100px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export default Image;
