import '../App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, redirect } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import MarkRate from '../components/MarkRate';
import styled from 'styled-components';

export const Result = () => {
  const [customer, setCustomer] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `https://api.eos.agency/customers/${id}`
        );
        console.log(res.data);
        setCustomer(res.data);
        setIsLoading(false);
      } catch (e) {
        window.location.href = 'https://eos.agency/';
      }
    })();
  }, [id]);

  return (
    <div className="App">
      {isLoading && (
        <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div>
      )}
      {!customer.crawled && !customer.isCrawling && (
        <>
          <PageTitle>Analyse du site {customer.site} en cours</PageTitle>
          <p>Votre demande va bientot être traitée</p>
        </>
      )}
      {customer.isCrawling && (
        <>
          <PageTitle>Analyse du site {customer.site} en cours</PageTitle>
          <p>Votre demande est en train d'étre traitée</p>
        </>
      )}
      {customer.crawled && (
        <>
          <PageTitle>Analyse du site {customer.site}</PageTitle>
          <MarkList>
            <MarkRate
              value={customer.fixed_mark_indexable_pages}
              title={'Note pages indexables'}
            />
            <MarkRate
              value={customer.fixed_mark_pages_404}
              title={'Note pages 404'}
            />
            <MarkRate
              value={customer.fixed_mark_pages_title_good}
              title={'Note titres'}
            />
            <MarkRate
              value={customer.fixed_mark_pages_description_good}
              title={'Note descriptions'}
            />
            <MarkRate
              value={customer.fixed_mark_pages_h1_good}
              title={'Note H1'}
            />
            <MarkRate
              value={customer.page_speed_home_score}
              title={'Note Google page speed de la page index'}
            />
            <MarkRate
              value={customer.page_speed_global_average}
              title={'Note Google page speed moyenne'}
            />
          </MarkList>
        </>
      )}
    </div>
  );
};

const PageTitle = styled.h1`
  text-align: center;
  margin-top: 50px;
  color: black;
  padding: 30px 0;
`;
const MarkList = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 50px auto;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
