import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StarRate = ({ rate, count = 5 }) => {
  const starSize = '40px';
  const starColor = '#fff';
  const starBackground = '#fc0';
  const rating = rate;
  const _percent = (rating / count) * 100;
  const [percent, setPercent] = useState(0);

  const incrementValueToX = (x) => {
    let value = 0;
    const interval = setInterval(() => {
      if (value < x) {
        value += 1;
        setPercent(value);
      } else {
        clearInterval(interval);
      }
    }, 9);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    const stopIncrementing = incrementValueToX(_percent);
    setTimeout(() => {
      stopIncrementing();
    }, 5000);
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <StarRatingContainer
        starSize={starSize}
        starColor={starColor}
        starBackground={starBackground}
        percent={`${percent}%`}
      />
    </div>
  );
};

export default StarRate;

const StarRatingContainer = styled.div`
  display: inline-block;
  font-size: ${({ starSize }) => starSize};
  line-height: 1;

  &:before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: ${({ percent, starBackground, starColor }) => `linear-gradient(
    90deg,
    ${starBackground} ${percent},
    ${starColor} ${percent}
  )`};
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
`;
