import './App.css';
import React from 'react';
import { List } from './pages/list';
import { Csv } from './pages/crawl';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Result } from './pages/result';
import { Validation } from './pages/validation';
import { CrawlForm } from './pages/crawl-form';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './proctedRoute';
import LoginPage from './pages/login';
import AuditWebGratuitResult from './pages/audit-web-gratuit-result/userId';

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element:
        <ProtectedRoute>
          <List />
        </ProtectedRoute>
    },
     {
      path: 'audit-web-gratuit-result/:userid',
      element:
          <AuditWebGratuitResult />
    },
    {
      path: '/crawl/:id',
      element: (
          <Csv />
      )
    },
    {
      path: '/result/:id',
      element: (
        <ProtectedRoute>
          <Result />
        </ProtectedRoute>
      )
    },
    {
      path: '/validation/:id',
      element: (
          <Validation />
      )
    },
    {
      path: '/audit-form',
      element: (
          <CrawlForm />
      )
    },{
       path: '/login',
      element: (
          <LoginPage />
      )
    }
  ]);

  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
      <AuthProvider router={router}>
        <RouterProvider router={router} />
      </AuthProvider>
  );
};

export default App;
