import { Knob } from 'primereact/knob';
// import { colorResolver } from '../../utils/colorResolver';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
const MarkColorResolver = {
  bad: '#CA0D0D',
  medium: '#EDAA29',
  good: '#19C90A'
};

const ImprovementBloc = ({ text, title, Icon }) => {
  return (
    <LineContainer>
      {Icon && <Icon />}
      <MarkTextSection>
        <MarkTitle>{title}</MarkTitle>
        <Value>{text}</Value>
      </MarkTextSection>
    </LineContainer>
  );
};

const LineContainer = styled.div`
  display: flex;
  font-family: Inter;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 50px;
`;
const MarkTitle = styled.span`
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  text-align: left;
  color: #29589e;
  margin-bottom: 15px;
`;
const Value = styled.span`
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
`;
const MarkTextSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;
export default ImprovementBloc;
