import React, { useEffect, useRef, useState,useMemo } from 'react';
import axios from 'axios';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import moment from 'moment';
// import Image from 'next/image';

import MarkRate from '../../components/MarkRate2';
import styled from 'styled-components';
// import { useRouter } from 'next/router';
import StarRate from '../../components/StarRate';
import MarkLine from '../../components/MarkLine';
import ImprovementBloc from '../../components/ImprovementBloc';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import {extractDomainFromURL,
        getSiteData,
        getImprovmentData,
        getResumeMark} from './dataFn'
  
export const AuditWebGratuitResult = () => {
  const { userid } = useParams();
  const [customer, setcustomer] = useState({});
  const toast = useRef(null);
  const id = userid;
  
  

  
  const resumeMark = useMemo(() => getResumeMark(customer), [customer]);
  const siteData = useMemo(() => getSiteData(customer), [customer]);
  const improvementData = useMemo(() => getImprovmentData(customer), [customer]);
  
  console.log({customer})
  const getDatas = async () => {
    try {
      const res = await axios.get(`https://api.eos.agency/customers/${id}`);
      return res.data
    }
    catch (e) {
      console.log(e)
    }
  }
  
  useEffect(() => {
   const fetchData = async () => {
    if (id && Object.keys(customer).length === 0) {
        getDatas().then(data=>setcustomer(data)); 
                
    }
  };

  fetchData(); 
  }, [id]);


  return (
    <>
      {!customer.crawled && !customer.isCrawling && customer.updatedAt && (
        <>
          <PageTitle>
            Analyse du site <br />
            {extractDomainFromURL(customer.site)}
          </PageTitle>
          <p>Votre demande va bientot être traitée</p>
        </>
      )}
      {customer.isCrawling && (
        <>
          <PageTitle>Analyse du site {customer.site} en cours</PageTitle>
          <p>Votre demande est en train d'étre traitée</p>
        </>
      )}
      {customer.crawled && !customer.fixed_mark_indexable_pages && (
        <>
          <PageTitle>
            Analyse du site <br />
            {customer.site}
          </PageTitle>
          <GlobalRateContainer>
            <H2>
              En l’état, votre site ne peut être inspecter.
              <br />
              <br />
              veuillez <a href="https://eos.agency/contact.html">contacter notre équipe</a> pour faire
              le point sur les solutions les plus adaptés
            </H2>
          </GlobalRateContainer>
        </>
      )}
      {customer.crawled && customer.fixed_mark_indexable_pages !== 0 && (
        <>
          <PageTitle>
            Analyse du site <br />
            {customer.site}
          </PageTitle>
          <PageDate>
            réalisé le {moment(customer.updatedAt).format('DD/MM/YYYY')}
          </PageDate>
          <MarkList>
            <GlobalRateContainer>
              <Label>Votre pré-Audit SEO</Label>
              <H2>Note finale</H2>
              <StarRate rate={customer.globalMark} />
            </GlobalRateContainer>

            <ResumeContainer>
              <Label>Résumé</Label>
              <H2>Perspectives d’optimisations</H2>
              <MarkLineContainer>
                {resumeMark.map(({ value, title, Icon }, index) => (
                  <MarkLine key={value} value={value} title={title} Icon={Icon} />
                ))}
              </MarkLineContainer>
            </ResumeContainer>

            <SiteDataTitle>Données du site</SiteDataTitle>
            {siteData.map(({ label, title, description, mark }, index) => (
              <LargeMarkContainer key={label}>
                <Label>{label}</Label>
                <MarkTitle>{title}</MarkTitle>
                <MarkRateContainer>
                  <MarkRate value={mark} />
                  <Text>{description}</Text>
                </MarkRateContainer>
              </LargeMarkContainer>
            ))}
          </MarkList>
          <ImprovementSection>
            <Label>Votre pré-Audit SEO</Label>
            <H2>Comment l’améliorer ?</H2>
            <ImprovementContainer>
              {improvementData.map((props, index) => (
                <ImprovementBloc {...props} key={index} />
              ))}
            </ImprovementContainer>
          </ImprovementSection>
          <QuestionContainer>
            <QuestionTitle>Une question ? un conseil ?</QuestionTitle>
            <QuestionText>
              Si vous voulez mieux comprendre ces résultats, en savoir plus, ou
              être accompagné pour améliorer votre site pour être mieux
              référencé.
            </QuestionText>
            <a href={'https://eos.agency/contact.html'} style={{ textDecoration: 'none' }}>
              <Button>On prend contact</Button>
            </a>
            <ShareBtn
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast.current.show({
                  severity: 'success',
                  summary: 'url copié dans le press papier'
                });
              }}
              className="pi pi-share-alt"
            />
            <Toast ref={toast} position="bottom-left" />
          </QuestionContainer>
        </>
      )}
    </>
  );
};

const ShareBtn = styled.i`
  margin: 50px auto 0;
  width: 50px;
  height: 50px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.44);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageTitle = styled.h1`
  text-align: center;
  margin-top: 50px;
  padding: 30px 0;
  font-size: 36px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #29589e;
`;

const SiteDataTitle = styled.h3`
  font-family: sans-serif;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  border-bottom: solid 1px black;
  padding-bottom: 15px;
  margin: 100px 0 55px;
`;

const PageDate = styled.h2`
  font-size: 22px;
  font-weight: 600;
  font-family: Roboto;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 23px;
`;
const MarkList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 1200px;
  margin: 50px auto;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const MarkRateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
const Text = styled.p`
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 8%;
  @media screen and (max-width: 800px) {
    padding-left: 0;
  }
`;

const RoundContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
  /*max-width: 702px;*/
  width: 90%;
  margin: 0 auto 60px;
  padding: 20px 0 95px;
  border-radius: 20px;
`;
const H2 = styled.h2`
  font-family: sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: #000000;
  margin: 0;
  padding: 0 0 20px;
  text-align: center;
`;
const Label = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: #29589e;
  margin-bottom: 20px;
`;

const MarkLineContainer = styled.div`
  margin-top: 90px;
  width: 90%;
  padding: 0 150px;
  @media screen and (max-width: 1200px) {
    padding: 0 80px;
  }
  @media screen and (max-width: 800px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 450px) {
    padding: 0 20px;
  }
`;

const ResumeContainer = styled(RoundContainer)`
  max-width: 811px;
`;
const GlobalRateContainer = styled(RoundContainer)`
  max-width: 702px;
`;
const ImprovementSection = styled(RoundContainer)`
  max-width: 1000px;
  padding-bottom: 0;
`;
const MarkTitle = styled.h3`
  font-family: sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 50px;
`;
const LargeMarkContainer = styled.div`
  padding: 40px 90px;
  margin-bottom: 50px;
  background: #f6f6f6;
  border-radius: 20px;
  max-width: 1440px;
  width: 95%;

  @media screen and (max-width: 800px) {
    padding: 40px 20px;
  }
`;
const ImprovementContainer = styled.div`
  width: 95%;
  padding: 62px 30px;
  @media screen and (max-width: 800px) {
    padding: 62px 0px;
  }
`;
// const ImageCustom = styled(Image)`
//   width: 55px;
//   height: 45px;

//   @media screen and (max-width: 800px) {
//     display: none;
//   }
// `;

const QuestionContainer = styled.div`
  max-width: 840px;
  margin: 148px auto;
`;
const QuestionTitle = styled.h3`
  font-family: sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 42px;
  @media screen and (max-width: 800px) {
    padding: 0 40px;
  }
`;
const QuestionText = styled.p`
  font-family: sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  @media screen and (max-width: 800px) {
    padding: 0 40px;
  }
`;

const Button = styled.button`
  background: #29589e;
  color:white;
  padding: 30px 40px;
  border-radius: 20px;
  font-family: sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  margin: 90px auto 0;
  display: block;
  text-decoration: none;
`;

const NotWorkingText = styled(H2)`
  padding: 50px;
`;
const ProgressSpinnerCustom = styled(ProgressSpinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
`;
const SpinnerContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
`;

export default AuditWebGratuitResult;
