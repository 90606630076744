import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';

const statusBodyTemplate = (rowData) => {
  const statusClass = () => {
    if (rowData.crawled) {
      return 'qualified';
    } else if (rowData.isCrawling) {
      return 'renewal';
    }
    return 'unqualified';
  };
  const statusText = () => {
    if (rowData.crawled) {
      return 'effectué';
    } else if (rowData.isCrawling) {
      return 'En cours';
    }
    return 'non traité';
  };

  return (
    <span className={`customer-badge status-${statusClass()}`}>
      {statusText()}
    </span>
  );
};

const linkBodyTemplate = (rowData) => {
  return (
    <Link to={`/result/${rowData._id}`}>
      <Button
        className="p-button-secondary"
        icon="pi pi-info-circle"
        iconPos="right"
      />
    </Link>
  );
};

const phoneBodyTemplate = (rowData) => {
  return (
    <a href={`tel:${rowData.phone}`}>
      <Button
        type="button"
        label={rowData.phone}
        className="mr-2"
        icon="pi pi-phone"
      />
    </a>
  );
};

const nameBodyTemplate = (rowData) => {
  return (
    <span>
      {rowData.firstName} {rowData.lastName}
    </span>
  );
};

const answeredTemplate = (rowData) => {
  const statusClass = () => {
    console.log(rowData.emailStatus);
    if (rowData.emailStatus == 'valid') {
      return 'qualified';
    }
    return 'unqualified';
  };
  const statusText = () => {
    if (rowData.emailStatus == 'valid') {
      return 'validé';
    }
    return 'non validé';
  };

  return (
    <span className={`customer-badge status-${statusClass()}`}>
      {statusText()}
    </span>
  );
};
const deleteBodyTemplate = (rowData) => {
  const handleClick = async () => {
    await axios.delete(`http://195.15.222.201:5000/customers/${rowData._id}`);
  };
  return (
    <Button
      onClick={handleClick}
      className="p-button-raised p-button-danger"
      icon="pi pi-trash"
    />
  );
};
//disabled={!rowData.crawled}

export {
  statusBodyTemplate,
  linkBodyTemplate,
  deleteBodyTemplate,
  phoneBodyTemplate,
  nameBodyTemplate,
  answeredTemplate
};
