import { Knob } from 'primereact/knob';
import { colorResolver } from '../../utils';
import styled from 'styled-components';
const MarkRate = ({ value, title, max = 5, step = 5 }) => (
  <MarkContainer>
    <MarkTitle>{title}</MarkTitle>
    <Knob
      value={value}
      step={step}
      max={max}
      readOnly
      valueColor={colorResolver(value)}
      textColor={colorResolver(value)}
    />
  </MarkContainer>
);

const MarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
  width: 230px;
  margin-bottom: 50px;
`;
const MarkTitle = styled.span`
  display: flex;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
`;
export default MarkRate;
