import { Knob } from 'primereact/knob';
import { colorResolver } from '../../utils/index.js';
import styled from 'styled-components';
const MarkRate = ({ value, max = 5, step = 5 }) => (
  <MarkContainer>
    <Knob
      value={value}
      step={step}
      max={max}
      readOnly
      strokeWidth={2}
      valueColor={colorResolver(value)}
      textColor={colorResolver(value)}
      size={300}
    />
  </MarkContainer>
);

const MarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
`;

export default MarkRate;
