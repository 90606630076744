import { Knob } from 'primereact/knob';
// import { colorResolver } from '../../utils/colorResolver';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
const MarkColorResolver = {
  bad: '#CA0D0D',
  medium: '#EDAA29',
  good: '#19C90A'
};

const MarkResolver = (value) => {
  if (value <= 50) return MarkColorResolver.bad;
  if (value <= 75) return MarkColorResolver.medium;
  return MarkColorResolver.good;
};

const MarkLine = ({ value, title, Icon }) => {
  const incrementValueToX = (x) => {
    let value = 0;
    const interval = setInterval(() => {
      if (value < x) {
        value += 1;
        setPercent(value);
      } else {
        clearInterval(interval);
      }
    }, 15);

    return () => clearInterval(interval);
  };

  const [percent, setPercent] = useState(0);
  useEffect(() => {
    const stopIncrementing = incrementValueToX(value);
    setTimeout(() => {
      stopIncrementing();
    }, 5000);
  }, []);
  return (
    <LineContainer>
      {Icon && <Icon />}
      <MarkTitle>
        {title}
        <Value color={MarkResolver(percent)}>{percent}</Value>%
      </MarkTitle>
    </LineContainer>
  );
};

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  width: 100%;
  margin-bottom: 50px;
`;
const MarkTitle = styled.span`
  font-weight: 500;
  text-align: left;
  margin: 0 16px;
`;
const Value = styled.span`
  color: ${({ color }) => color};
  font-weight: bold;
  margin-left: 20px;
`;
export default MarkLine;
