import '../App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

import { Button } from 'primereact/button';

export const Validation = () => {
  const { id } = useParams();
  const [isValidate, setIsValidate] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const validateEmail = async (id) => {
    try {
      await axios.put(`https://api.eos.agency/customers/validate/${id}`,{});
      setIsValidate(true);
      setIsLoading(false);
      setTimeout(() => {
        window.location.href = 'https://eos.agency/';
      }, 3000);
    } catch (e) {
      console.error(e)
      setError(e);
    }
  };
  useEffect(() => {
    validateEmail(id);
  }, []);

  return (
    <div className="App">
      {isLoading && (
        <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div>
      )}
      {error && <p>{JSON.stringify(error)}</p>}

      {isValidate && (
        <p>
          Votre email à été validé vous serez notifier quand l'audit sera
          effectué
        </p>
      )}
    </div>
  );
};
