
import  React, {useEffect, useState} from 'react';
import Papa from 'papaparse';
import {
    useParams,
  } from "react-router-dom";

export const Csv = () => {
    const [parsedCsvData, setParsedCsvData] = useState([]);
    const { id } = useParams();
    useEffect(() => {
        async function getData() {
            const response = await fetch(`/sf-outputs/${id}/internal_all.csv`);
            const reader = response.body.getReader();
            const result = await reader.read();
            const decoder = new TextDecoder("utf-8");
            const csv = decoder.decode(result.value);
            const results = Papa.parse(csv, { header: true });
            const rows = results.data;
            setParsedCsvData(rows);
        }
        getData();
    }, []);
  
    return (
        <div className="Archive">
            {
                !!parsedCsvData.length && (
                <table className="ArchiveTable">
                    <thead>
                        <tr>
                        {Object.keys(parsedCsvData[0]).map(key=><th>{key}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {parsedCsvData &&
                            parsedCsvData.map((parsedData, index) => (
                                <tr key={index}>
                                {Object.keys(parsedData).map(key=><td>{parsedData[key]}</td>)}
                                </tr>
                            ))}
                    </tbody>
                </table>
                )
            }
            
        </div>
    );
  }
  
  