import Image from "../../components/Image";

export const extractDomainFromURL = (url)=> {
    try {
      const urlObject = new URL(url);
      return urlObject.hostname;
    } catch (error) {
      console.error("L'URL n'est pas valide.", error);
      return url;
    }
  }
export const getSiteData = (customer) => [
    {
      label: 'Indexabilité',
      title: 'Pages indexables',
      description: (
        <>
          L'indexabilité détermine si une page est répertoriée par les moteurs
          de recherche. <br />
          <br />
          Un taux élevé de pages HTML indexables indique une bonne visibilité
          potentielle sur les moteurs de recherche. <br />
          <br />
          Il est essentiel d'indexer uniquement les pages pertinentes pour
          garantir un référencement optimal et éviter le contenu superflu ou
          dupliqué.
        </>
      ),
      mark: customer.fixed_mark_indexable_pages
    },
    {
      label: 'Erreurs',
      title: 'Pages 404',
      description: (
        <>
          Les erreurs 404 indiquent l'absence de la page demandée.
          <br />
          <br />
          Elles peuvent nuire à votre référencement, car les moteurs de
          recherche les perçoivent comme des signes de négligence.
          <br />
          <br />
          Il est essentiel de les corriger en redirigeant vers une page
          pertinente ou en offrant une page d'erreur 404 personnalisée.
          <br />
          <br />
          Assurez-vous que les redirections conservent la pertinence du mot-clé
          ciblé pour maintenir la notoriété de la page.
        </>
      ),
      mark: customer.fixed_mark_pages_404
    },
    {
      label: 'Meta Title',
      title: 'Nomenclature pour les moteurs de recherche',
      description: (
        <>
          Le Méta Title est le titre de votre page qui s'affiche dans les
          résultats des moteurs de recherche.
          <br />
          <br />
          Il est crucial d'y intégrer le mot-clé ciblé de la page pour renforcer
          sa pertinence et améliorer son positionnement. <br />
          <br />
          Un titre bien rédigé, contenant le mot-clé pertinent, peut augmenter
          la visibilité de votre page et son taux de clics.
        </>
      ),
      mark: customer.fixed_mark_pages_title_good
    },
    {
      label: 'Meta Description',
      title: 'Description pour les moteurs de recherche',
      description: (
        <>
          Les Méta Descriptions offrent un aperçu du contenu de vos pages web
          dans les résultats des moteurs de recherche.
          <br />
          <br />
          Pour être efficace, elle doit inclure le mot-clé ciblé de la page, car
          cela renforce la pertinence de votre page aux yeux des moteurs de
          recherche et des utilisateurs.
          <br />
          <br />
          Une méta description optimisée, ni trop courte ni trop longue, peut
          augmenter le taux de clics (CTR) et améliorer le positionnement de
          votre page dans les résultats de recherche.
        </>
      ),
      mark: customer.fixed_mark_pages_description_good
    },
    {
      label: 'H1',
      title: 'Titre principal de la page',
      description: (
        <>
          La présence d'un titre selon les normes du Web est obligatoire pour un
          bon référencement. <br />
          <br />
          Le titre principal de la page est visible sur votre site par vos
          internautes. Très proche du Meta Title cité plus haut, il peut
          néanmoins être optimisé pour répondre aux recherches de vos
          internautes. <br />
          <br />
          Assurez-vous que vos Titres principaux soient attrayant et en rapport
          avec le contenu de votre page.
        </>
      ),
      mark: customer.fixed_mark_pages_h1_good
    },
    {
      label: 'Vitesse de la page principale',
      title: 'Home : PageSpeed',
      description: (
        <>
          La vitesse de chargement de la page d'accueil est primordiale.
          <br />
          <br />
          C'est souvent la première impression que les visiteurs ont de votre
          site.
          <br />
          <br />
          Une page d'accueil qui se charge rapidement améliore l'expérience
          utilisateur et réduit le taux de rebond, ce qui est favorable pour le
          référencement.
        </>
      ),
      mark: customer.page_speed_home_score
    },
    {
      label: 'Vitesse globale du site',
      title: 'Site : PageSpeed',
      description: (
        <>
          Les robots des moteurs de recherche qui permettent l'indexation de
          votre site passent très peu de temps sur chaque page. <br /> <br />
          Il est important que les pages chargent vite et que rien ne vienne
          bloquer les robots. <br /> <br />
          Pour ce faire, il est recommandé d'avoir une version light et Mobile
          First de votre site qui, une fois chargé, pourra ajouter des effets
          cosmétiques comme des animations ou des effets de style.
        </>
      ),
      mark: customer.page_speed_global_average
    }
];
  
export const getImprovmentData = (customer) => [
    {
      Icon: () => (
        <Image src="/img/world-icon.svg"  />
      ),
      title: 'Des audits plus poussés',
      text: (
        <>
          Vous pouvez demander un <b>Audit Qualité</b> pour déterminer
          efficacement les pages avec le plus fort potentiel de référencement et
          révéler les différentes erreurs qui nuisent à la réputation du site
          internet.
          <br /> <b>Un Audit Complet</b> vous donnera tous les éléments
          techniques et sémantiques et vous indiquera aussi tous les points à
          solutionner ou à optimiser dans l'ordre de priorité comme une liste de
          tâches. <b>Un Audit Concurrentiel</b> vous montrera comment vos
          concurrents communiquent, et sur quels mots clés. Cet audit est aussi
          très utile avant la sortie d'un nouveau produit.{' '}
          <a href="https://eos.agency/">
            En savoir plus sur nos audits
          </a>
        </>
      )
    },
    {
      Icon: () => (
        <Image src="/img/world-icon.svg"  />
      ),
      title: 'Optimisation Technique SEO',
      text: (
        <>
          L'optimisation techniques SEO consiste à intervenir sur le code pour
          correspondre aux normes du web, aux normes d'accessibilité et bien
          entendu, aux critères des moterus de recherche. L'objectif est
          d'améliorer entre autre, la qualité globale et les performances du
          site, la qualité du code et du balisage du site, la qualité et le
          poids des médias du site, et son accessibilité.{' '}
          <a href="https://eos.agency/">
            En savoir plus sur l'optimisation technique
          </a>
        </>
      )
    },
    {
      Icon: () => (
        <Image src="/img/world-icon.svg"  />
      ),
      title: 'Optimisation Sémantique SEO',
      text: (
        <>
          L’optimisation de contenu est sans doute l’opération la plus utile si
          elle est combinée avec de la création de contenu régulier. Elle permet
          avant tout de structurer votre contenu et donc de guider au mieux
          l’internaute et Google vers les pages les plus pertinentes de votre
          site. Chaque page se doit avoir un rôle et un but. Dès lors, elles
          doivent avoir un champ sémantique qui leur est propre.{' '}
          <a href="https://eos.agency/">
            En savoir plus sur l'optimisation sémantique
          </a>
        </>
      )
    },
    {
      Icon: () => (
        <Image src="/img/world-icon.svg"  />
      ),
      title: 'Faire de la pub SEA',
      text: (
        <>
          Vous souhaitez améliorer votre trafic rapidement ? Le moyen le plus
          efficace reste de faire une campagne Paid Media, plus communément
          appelée campagne SEA. Il s’agit de l’achat de mots-clés sur les
          moteurs de recherche pour apparaitre immédiatement dans les premiers
          résultats.{' '}
          <a href="https://eos.agency/">
            En savoir plus sur le Paid Media et Google Ads
          </a>
        </>
      )
    }
]

  export const getResumeMark = (customer)=> [
    {
      Icon: () => (
        <i
          classNames="pi pi-chart-line"
          style={{ fontSize: '35px', color: '#29589E' }}
        />
      ),
      title: 'Optimisation possible :',
      value: getPercent(customer.globalMark)
    },
    {
      Icon: () => (
        <i classNames="pi pi-star" style={{ fontSize: '35px', color: '#29589E' }} />
      ),
      title: 'Indexabilité des pages :',
      value: getPercent(customer.fixed_mark_indexable_pages)
    },
    {
      Icon: () => (
        <i classNames="pi pi-star" style={{ fontSize: '35px', color: '#29589E' }} />
      ),
      title: 'Erreurs 404 des pages :',
      value: getPercent(customer.fixed_mark_pages_404)
    },
    {
      Icon: () => (
        <i classNames="pi pi-star" style={{ fontSize: '35px', color: '#29589E' }} />
      ),
      title: 'Titre des pages sur les moteurs de recherche :',
      value: getPercent(customer.fixed_mark_pages_title_good)
    },
    {
      Icon: () => (
        <i classNames="pi pi-star" style={{ fontSize: '35px', color: '#29589E' }} />
      ),
      title: 'Descriptions des pages :',
      value: getPercent(customer.fixed_mark_pages_description_good)
    },
    {
      Icon: () => (
        <i classNames="pi pi-star" style={{ fontSize: '35px', color: '#29589E' }} />
      ),
      title: 'Titres des pages :',
      value: getPercent(customer.fixed_mark_pages_h1_good)
    },
    {
      Icon: () => (
        <i classNames="pi pi-star" style={{ fontSize: '35px', color: '#29589E' }} />
      ),
      title: 'Rapidité du site:',
      value: getPercent(customer.page_speed_global_average)
    }
  ]

 export const getPercent = (value, max = 5) => {
    return ((value / max) * 100).toFixed(0);
 };